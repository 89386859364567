.shadow-container {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.left-container {
  vertical-align: top;
  height: 85vh;
  display: inline-block;
  width: 15%;
}

.right-container {
  vertical-align: top;
  display: inline-block;
  width: 85%;
}

.trade-container {
  position: relative;
  height: 80vh;
  overflow: hidden;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
