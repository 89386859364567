.showTip {
  position: fixed;
  font-weight: 700;
  display: inline-block;
  z-index: 99999;
  pointer-events: none;
  opacity: 1;
  white-space: nowrap;
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
}

.hideTip {
  opacity: 0;
}
