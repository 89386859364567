.log-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  /* display: block; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.log-table .log-td,
.log-table .log-th {
  border: 1px solid #ddd;
  padding: 8px;
}

.log-table .log-tr:nth-child(even) {
  background-color: #f2f2f2;
}

.log-table .log-tr:nth-child(odd) {
  background-color: #fff;
}

.log-table .log-tr:hover {
  background-color: #ddd;
}

.log-table .log-th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #e31937;
  color: white;
}
