/* .strategy-container{
    background-color: grey;
    padding: 10px;
} */
.strategy-container {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px;
    font-weight: 900;
    font-size: 6px;
    transition: ease all 100ms
  }
  
.strategy-container.light{
background-color: #f0f2f6;
color: #f63466;
}

.strategy-container.dark{
background-color: var(--dark-theme-darkest);
color: #f7a90d;
}
  