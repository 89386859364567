.theme-wrapper {
  width: 100vh;
  height: 100vh;
}

.theme-switch-button {
  z-index: 99999;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  font-size: 2rem;
  transition: all ease 500ms;
}

.theme-switch-button:hover {
  transform: scale(1.2);
}

.theme-switch-button.light:active {
  background-color: #f63466;
}

.theme-switch-button.dark:active {
  background-color: #f7a90d;
}
