.cap-btn-div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  
  .cap-button-59 {
    width: 50%;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    min-width: 140px;
    outline: 0;
    padding: 0 17px;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .cap-button-59.light {
    background-color: var(--light-theme-orange);
    border: 2px solid var(--light-theme-orange);
    color: var(--light-theme-white);
    fill: var(--light-theme-orange);
  }
  .cap-button-59.light:focus {
    color: var(--light-theme-purple);
  }
  
  .cap-button-59.light:hover {
    border-color: var(--light-theme-purple);
    background-color: var(--light-theme-purple);
    color: var(--light-theme-white);
    fill: var(--light-theme-white);
    transform: scale(1.05);
  }
  
  .cap-button-59.dark {
    border-color: var(--light-theme-orange);
    background-color:var(--light-theme-orange);
    border: 2px solid var(--light-theme-orange);
    color: var(--dark-theme-lighter-dark);
    fill: var(--dark-theme-lighter-dark);
  }
  
  .cap-button-59.dark:hover {
    border-color: var(--dark-theme-grey);
    background-color: var(--dark-theme-grey);
    color: var(--light-theme-white);
    fill: var(--light-theme-white);
    transform: scale(1.05);
  }
  
  @media (min-width: 768px) {
    .cap-button-59 {
      min-width: 170px;
    }
  }