.graph-holder {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px;
  font-weight: 900;
  font-size: 6px;
  transition: ease all 100ms
}

.graph-holder.light{
  background-color: #f0f2f6;
  color: #f63466;
}

.graph-holder.dark{
  background-color: var(--dark-theme-darkest);
  color: #f7a90d;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
