*,
*:before,
*:after {
  box-sizing: border-box;
}

.cap-large {
  font-size: 2em;
}

.cap-small {
  font-size: 0.5em;
}

.cap-glass {
  width: 3.5em;
  height: 5em;
  border-style: solid;
  border-width: 2.5em 1.2em;
  border-radius: 10%;
  position: relative;
  animation: spin 5s linear infinite;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.cap-glass.light{
  border-color: var(--light-theme-orange) transparent;
}

.cap-glass.dark{
  border-color: var(--dark-theme-purple) transparent;
}

.cap-glass:before {
  content: "";
  width: 2.5em;
  height: 4.5em;
  border-style: solid;
  border-width: 2.25em 1.1em;
  border-radius: 10%;
  position: absolute;
  top: -2.25em;
  left: -0.7em;
  animation: slideOut 5s linear infinite;
}

.cap-glass.light:before{
  border-color: var(--light-theme-offwhite) transparent transparent;
}

.cap-glass.dark:before{
  border-color: var(--dark-theme-darkest) transparent transparent;
}


.cap-glass:after {
  content: "";
  width: 2.5em;
  height: 4.5em;
  border-style: solid;
  border-width: 0em 1.1em;
  border-radius: 10%;
  position: absolute;
  top: -2.25em;
  left: -0.7em;
  animation: slideIn 5s linear infinite;
}

.cap-glass.light:after {
  border-color: transparent transparent var(--light-theme-offwhite) ;
}

.cap-glass.dark:after{
  border-color: transparent transparent var(--dark-theme-darkest);
}


.cap-progress-bar {
  text-align: right;
  background-color: var(--light-theme-yellow);
  color: var(--light-theme-grey);
  border-top: 2px solid var(--light-theme-light-yellow);
  border-bottom: 2px solid var(--light-theme-light-yellow);
  border-right: 2px solid var(--light-theme-light-yellow);
}

.cap-progress-bar.light {}
.cap-progress-bar.dark {}

.cap-time-first {
  background-color: var(--light-theme-yellow);
  border: 2px solid var(--light-theme-light-yellow);
  vertical-align: top;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  padding-left: 1%;
  padding-right: 1%;
  color: var(--light-theme-grey);
  width: 44%;
  white-space: nowrap;
}

.cap-time-second {
  vertical-align: top;
  display: inline-block;
  width: 55%;
}

.cap-time-wrap {
  display: block;
}


@keyframes spin {
  0% {
    transform: rotation(0deg);
  }
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes slideOut {
  0% {
    top: -2.25em;
    width: 2.5;
    border-width: 2.25em 1.1em;
  }
  90% {
    top: 0em;
    width: 0;
    border-width: 0em 0em;
    left: 0.55em;
  }
  100% {
    top: 0em;
    width: 0;
    border-width: 0em 0em;
    left: 0.55em;
  }
}
@keyframes slideIn {
  0% {
    border-width: 0em 1.1em;
  }
  90% {
    border-width: 2.25em 1.1em;
  }
  100% {
    border-width: 2.25em 1.1em;
  }
}
