.live-ticker-btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 10px; */
  padding-bottom: 5px;
}

.live-ticker-btn {
  align-items: center;
  border: 2px solid #f7a90d;
  background-color: #f7a90d;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  fill: #f7a90d;
  font-size: 12px;
  font-weight: 600;
  height: 30px;
  justify-content: center;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.live-ticker-btn:focus {
  color: #f63466;
}

.live-ticker-btn:hover {
  border-color: #f63466;
  background-color: #f63466;
  color: #fff;
  fill: #fff;
}

.live-ticker-btn:active {
  border-color: #fff;
  color: #fff;
  fill: #fff;
}
