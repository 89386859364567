

.box {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  max-width: 100%;
  max-height: 100%;
  transition: ease all 100ms;
}

.toolbar {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: #F7A90D;
  cursor:default;
}

.plus-button {
  margin: 5px;
  background-color: #000;
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;
  border-radius: 1.2rem;
  padding-left: 5px;
  padding-right: 5px;
  transition: ease all 200ms;
}


.plus-button.light {
  background-color: #000;
  color: #fff;
}

.plus-button.light:hover{
  background-color: #F63466;
}

.plus-button.dark {
  background-color: #000;
  color: #fff;
}

.plus-button.dark:hover{
  background-color: #F63466;
}

.minus-button {
  margin: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 1.2rem;
  padding-left: 8px;
  padding-right: 8px;
  transition: ease all 200ms;
}

.minus-button.light {
  background-color: #000;
  color: #fff;
}

.minus-button.light:hover{
  background-color: #F63466;
}

.minus-button.dark {
  background-color: #000;
  color: #fff;
}

.minus-button.dark:hover{
  background-color: #F63466;
}



/* .resizer {
  position: absolute;
  background-color: #F7A90D;
  height: 14px;
  width: 7px;
  transform: rotate(45deg);
}

.pseudo-resizer{
 position: absolute;
 height: 7px;
 width: 7px;
 right: 0;
 bottom: 0;
 overflow: hidden;
} */

@media (max-width: 767px) {
  .container {
    border: double 5px transparent;
    border-radius: 10px;
  }

  .box {
    border: double 2.5px transparent;
    border-radius: 5px;
  }
}
