.cap-info-first {
    vertical-align: top;
    display: inline-block;
    text-align: right;
    font-weight: 600;
    color:  var(--light-theme-grey);
    white-space: nowrap;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-right: 10px;
  }
  
  .cap-info-wrap {
    display: block;
  }