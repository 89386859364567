* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  --light-theme-orange: #f7a90d;
  --light-theme-yellow: #fffd80;
  --light-theme-light-yellow: #f7f3e8;
  --light-theme-purple: #f63466;
  --light-theme-grey: #f0f2f6;
  --light-theme-white: #fff;
  --light-theme-offwhite: #f7f3e8;
  --light-theme-grey: #424141;
  --dark-theme-black:#000;
  --dark-theme-darkest: #0e1117;
  --dark-theme-lighter-dark: #31333f;
  --dark-theme-purple: #f63466;
  --dark-theme-grey: grey;
  --dark-light-grey: lightgrey;

  --notification-success: #73af55;
  --notification-error: #d06079;
}

.overlay {
  opacity: 0.8;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.overlay.dark {
  background-image: linear-gradient(
    360deg,
    var(--dark-theme-darkest),
    var(--dark-theme-lighter-dark)
  );
}

.app.dark {
  background-color: var(--dark-theme-lighter-dark);
}


/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .app {
    margin: auto;
    max-width: 95%;
    margin-bottom: 40px;
    margin-top: 7%;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .app {
    margin: auto;
    max-width: 90%;
    margin-top: 7%;
    margin-bottom: 40px;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .app {
    margin: auto;
    max-width: 75%;
    margin-bottom: 40px;
    margin-top: 7%;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  .app {
    margin: auto;
    max-width: 85%;
    margin-top: 4%;
    margin-bottom: 40px;
  }
}
