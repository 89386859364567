.btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 5px;
}

.sub-button {
  width: 50%;
}

.login-icon {
  display: flex;
  justify-content: center;
}

.form {
  padding: 20px;
  border-radius: 10px;
}

.form.light {
  background-image: linear-gradient(to bottom, #f7f3e8, #fff);
}

.form.dark {
  background-image: linear-gradient(to bottom, #31333f, #000);
}
