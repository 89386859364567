.login-graph {
  max-height: 80vh;
  font-weight: bolder;
  font-size: 20px;
}

.login-graph.dark {
  color: #f7a90d;
}

.login-graph.light {
  color: #f63466;
}
