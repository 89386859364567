.console-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.console-container.light {
  background-color: #f0f2f6;
  border-right: 2px solid pink;
}

.console-container.dark {
  background-color: #000;
  border-right: 2px solid #f7a90d;
}

.bloc-tabs {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.tabs {
  font-weight: 550;
  font-size: 0.8rem;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.tabs.light {
  color: #fff;
  background-color: #f7a90d;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
}

.tabs.dark {
  color: #fff;
  background-color: var(--dark-theme-lighter-dark);
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  /* background: #fff; */
}

.active-tabs.light {
  border-bottom: 1px solid #f63466;
  background-color: #f63466;
}

.active-tabs.dark {
  color: #000;
  border-bottom: 1px solid #f7a90d;
  background-color: #f7a90d;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  /* background: rgb(88, 147, 241); */
}

button {
  border: none;
}

.content-tabs {
  /* flex-grow: 1; */
}
.content {
  padding: 10px;
  width: 100%;
  height: 100%;
  display: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
  height: 100%;
}
